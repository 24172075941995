// export const BaseUrl = 'https://back.moneymindertradingcompany.co.in';


// export const BaseUrl = 'https://88.222.215.234:7000';
export const BaseUrl = 'https://bloodbackoffice.onrender.com';
// export const BaseUrl = 'http://localhost:7000';




// export const BaseUrl = 'https://blood-back-office.vercel.app';
